#create_menu_plan {
    margin-bottom: 10%;

    h1{
        color: $site_blue;
    }

    #each-day-menu {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        margin-bottom: 5%;

        header {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-left: 2%;
            padding: 0;

            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            h3 {
                font-size: 2rem;
                margin-left: 2%;
            }

            p {
                margin-top: 42px;
                margin-left: 10px;
                font-size: 1.2rem;
            }
        }

        #time_of_day {
            .MuiGrid-item {
                section {
                    border: 1px solid rgba(0, 0, 0, 0.3);
                    border-radius: 4px;
                    margin: 5%;
                    text-align: center;
                    
                }
            }
        }

        .add_meal_btn_container {
            border: 1px solid rgba(0, 0, 0, 0.3);
            border-radius: 4px;
            margin: 5%;

            button {
                width: 100%;
                color: $site_blue;
                text-transform: capitalize;
            }
        }

        .selected_meal {
            background: #F6F6F6;
            border: 1px solid rgba(0, 0, 0, 0.3);
            border-radius: 4px;
            margin: 5%;

            p {
                font-size: 1rem;
                line-height: 1.2rem;
                letter-spacing: 0.04em;
                margin: 5%;
                margin-bottom: 15%;
            }

            button {
                border: 1px solid rgba(0, 0, 0, 0.3);
                border-radius: 4px;
                margin: 5%;

                font-size: 1rem;
                font-weight: 500;

                padding-right: 5%;
                padding-left: 5%;
            }
        }

        .add_more_meal_btn_container {
            display: flex;
            flex-direction: row-reverse;

            button {
                margin: 2%;
                margin-top: 0;
                text-transform: capitalize;
                background-color: white;
                color: $site_blue;

                font-size: 1rem;
                font-weight: 500;

                span {
                    margin-right: 7px;
                    margin-top: 2%;
                }

                &:hover {
                    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
                    border-radius: 4px;
                }
            }
        }
    }

    #menu_plan_add_meal {
        button {
            font-size: 1rem;
            font-weight: 500;

            span {
                padding-top: 1%;
                margin-left: 1%;
            }
        }
    }
}

#create_menu_plan_form{
    #menu_plan_calender{
    button {
        background-color: transparent;
        color: $site-blue;
        padding: 0;
        font-size: 1rem;

        &:hover {
            background-color: transparent;
            border: none;
        }
    }
}
}