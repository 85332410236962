#meal_details_pop_up {
    p {
        font-weight: 300;
        font-size: 20px;
        letter-spacing: 0.04em;
        color: #303030;
    }
}

#other_meal_details {
    h4 {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 24px;
        letter-spacing: 0.04em;
        color: $site_blue;

        &:first-child {
            margin-top: 0;
        }
    }

    p {
        margin-top: 0;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.04em;
        color: #303030;
    }
}

#mp_order_frame {
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 30px !important;
    font-family: $roboto;
    letter-spacing: 0.04em;
    color: #303030;

    #scrollable_part {
        height: 100vh;
        overflow: auto;
    }

    .vertical_rule {
        margin-top: 0;
        margin-bottom: 0;
    }

    #general_details {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);

        .text_key {
            font-weight: 300;
            font-size: 15px;

            p {
                margin-top: 18px;
            }
        }

        .text_value {
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 400;
        }

        .text_bold {
            font-weight: 600;
            font-size: 22px;

            p {
                margin-top: 15px;
            }
        }

        #text_badge {
            margin-top: 10px;
        }

        .collected_remaining {
            font-size: 18px;
            margin-top: 30px;

            p {
                margin-bottom: 0;
            }
        }

        .collected_remaining_values {
            padding-left: 30px;

            p {
                margin-top: 0;
            }
        }

        #collected {
            color: $site_red;
        }

        #remaining {
            color: $site_blue;
        }

        #remaining,
        #collected {
            font-size: 24px;
            font-weight: 700;
        }

        #date_time_container {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-bottom: 30px;

            span {
                margin-left: 30px;
                font-weight: 400;
                font-size: 13px;
                color: rgba(48, 48, 48, 0.6);

                &:last-child {
                    font-weight: 500;
                    color: rgba(48, 48, 48, 0.75);
                }
            }
        }
    }

    #plan_details {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        margin-top: 50px;

        #plan_day {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 20px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            h2,
            p {
                margin: 0;
                color: #000000;
            }

            p {
                margin-left: 10px;
                font-weight: 300;
                font-size: 18px;
            }

            h2 {
                font-weight: 500;
                font-size: 30px;
            }
        }

        #plan_time_of_day {
            padding: 20px;

            #title {
                border: 1px solid rgba(0, 0, 0, 0.3);
                border-radius: 4px;
                text-align: center;
                padding: 10px 0;

                h3 {
                    margin: 0;
                    font-weight: 400;
                    font-size: 24px;
                    color: #000000;
                }
            }

            #meal_details {
                background: #F6F6F6;
                border: 1px solid rgba(0, 0, 0, 0.3);
                border-radius: 4px;
                margin-top: 5px;
                padding: 5px;
            }


        }
    }

    .form-btn {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        button {
            &:last-child {
                background-color: $site_red;

                &:hover {
                    border: 1px solid $site_red;
                    color: $site_red;
                    background-color: $site_gray;
                }
            }
        }
    }
}