#new_order_title {
    color: $site_blue;
}

#new_order_btns {
    button {
        &:first-child {
            color: $site_blue;
        }

        &:last-child {
            color: $site_red;
        }
    }
}