#create_role {
    #role_description {
        margin-bottom: 50px;
    }

    p,
    #inner_permissions {
        margin-left: 30px;
    }

    #accordionTitle {
        #permissions_given {
            color: $site_blue;
            font-weight: 700;
        }

        .MuiFormControlLabel-label {
            font-size: 1.3rem;

            font-weight: 500;
            letter-spacing: 0.04em;
        }
    }

    .form-btn {
        margin-top: 10%;
    }

    #accordionTitle {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    #inner_permissions {
        padding-bottom: 50px;
    }
}