#create_voucher {
    #add_benefits {
        box-shadow: 4px 4px 18px 2px rgba(17, 97, 123, 0.07);
        border-radius: 6px;
        padding: 30px;

        .MuiOutlinedInput-root {
            margin-top: 10px;
        }

        p {
            color: $site_blue;
            font-weight: 700;
        }

        #add_btn {
            display: flex;
            flex-direction: row-reverse;
        }

        button {
            background-color: white;
            padding: 0;
            font-size: 1rem;
            color: $site_blue;
            margin-top: 2%;
            padding: 2px 10px;
            font-weight: 300;

            span {
                padding-right: 5px;
                padding-top: 5px;
            }

            &:hover {
                box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                border: none;
            }
        }
    }
}