#mouse-over-popover {
    padding-left: 30px;
    padding-right: 10px;

    p {
        margin-top: 0;
        font-weight: 300;
        font-size: 16px;

        color: #303030;
    }

    h2 {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 24px;

        color: $site_blue;
    }
}