#customer_details {
    #phone {
        color: $site_blue;
    }

    #debt {
        color: $site_red;
        font-weight: bold;
        font-size: 8px;
    }
}

.popup_search {
    button {
        border: 0.5px solid rgba(196, 196, 196, 0.67);
        border-radius: 3px;
        margin-bottom: 20px;
        font-size: 10px;
        color: $site_blue;
    }
}

#pos_contact {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 !important;

    #contact_text {
        margin-left: 10px;
    }

    p {
        margin-top: 0;
    }
}