.form-dropdown {
    box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.07);
    margin-top: 1%;
    padding: 0.5% 0;

    p {
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 2%;
        cursor: pointer;

        &:hover {
            background-color: rgba(0, 0, 0, 0.07);
        }
    }
}

.MuiInputAdornment-root {
    cursor: pointer;
}

// category
.category-input {
    .MuiFormControl-root {
        margin-top: 0;
        margin-right: 5%;
        width: 71.6%;
    }

    span {
        margin-top: 5%;

        button {
            padding-bottom: 1.5%;

            background-color: $site-blue;
            color: white;
            padding: 1% 5%;
            font-size: 1.5rem;

            &:hover {
                color: $site-blue;
                background-color: transparent;
                border: 1px solid $site-blue;
            }
        }
    }
}

.form-btn {
    text-align: center;
    margin-top: 5%;

    button {
        background-color: $site-blue;
        color: white;
        padding: 1% 5%;
        font-size: 1.5rem;

        &:hover {
            color: $site-blue;
            background-color: $site_gray;
            border: 1px solid $site-blue;
        }
    }
}

.form_left_section {
    padding-right: 5%;
}

.form_right_section {
    padding-left: 5%;
}

.form_frame {
    border: 1px solid $site-blue;
    padding: 5%;
    border-radius: 10px;
    padding-right: 0;

    .MuiGrid-item {
        padding-right: 5%;
        margin-bottom: 5%;
    }

    h4 {
        font-weight: 500;
        font-size: 1.2rem;
        letter-spacing: 0.04em;
    }

    button {
        background-color: $site-blue;
        color: white;
        padding: 1% 5%;
        font-size: 1.5rem;

        &:hover {
            color: $site-blue;
            background-color: transparent;
            border: 1px solid $site-blue;
        }
    }
}