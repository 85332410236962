#branch_set_up {
    margin-top: 20px;

    .MuiGrid-container {
        margin-bottom: 3%;
    }

    main {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        // padding: 2%;

        .MuiGrid-container {
            padding-left: 2%;
            padding-right: 2%;
        }
    }

    .area {
        padding-right: 5%;
    }

    #region_city {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        padding-bottom: 60px;

        #region {
            padding-right: 5%;
        }

        #city {
            padding-left: 5%;
        }
    }

    #add_more_area_btn {
        button {
            font-weight: 700;
            text-transform: capitalize;
            color: $site_blue;
            font-size: 1rem;

            span {
                margin-right: 10px;
            }
        }
    }

    #set_work_hours {
        h4 {
            color: $site_blue;
            font-weight: 400;
        }
    }

    .form-btn {
        margin-top: 10%;
    }
}