@include media('>phone', '<=tablet') {
    // background-color: red !important;

    // pos
    #pos_order {
        letter-spacing: 0.04em;

        #pos_menu_items {
            h1 {
                font-size: 20px;
            }
        }

        #top_pos_btns {
            margin-bottom: 30px;

            button {
                font-size: 10px;
            }
        }

        #show_cart_btn {
            button {
                font-size: 10px;
            }
        }
    }

    #pos_filter {
        width: 250px;

        .MuiTextField-root,
        .MuiInputBase-root {
            margin-bottom: 10px;
            width: 250px;
        }

        button {
            width: 100px;
            margin: 12px;
        }
    }

    // pos menu item popup
    #pos_menu_item_popup {
        #menu_item_addons {
            padding: 10px;
            margin-bottom: 10px;

            h3 {
                font-size: 16px;
            }

            #menu_item_addons_list {
                margin: 5px;
                padding: 5px;
            }

            #menu_item_selected_addons {
                p {
                    font-size: 10px;
                }
            }
        }

        .MuiFormControlLabel-root {
            font-size: 10px;
        }
    }
}