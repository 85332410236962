.kitchen_slips {
  font-family: $Inconsolata;
  width: 300px;
  letter-spacing: 0.02em;
  padding: 0 5px;
  color: black;

  .order_details_top {
    padding-bottom: 10px;
  }

  .order_details_down {
    padding-top: 10px;
  }

  h2 {
    font-size: 20px;
    text-align: center;
    font-family: $roboto_mono;
    font-weight: 700;
  }

  h3 {
    margin: 0;
    font-weight: 900;
    font-size: 15px;
    line-height: 16px;
  }

  h4 {
    font-weight: 400;
    font-size: 16px;
    margin: 0;
    text-transform: uppercase;

    &:first-child {
      font-weight: 800;
      margin-right: 5px;
    }
  }

  p {
    margin: 0;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
  }

  .cart_item_details {
    padding: 10px 0;

    #cart_item_heading {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    #item_details {
      margin: 10px 0;

      section {
        p {
          font-weight: 900;
          font-size: 14px;
          margin-bottom: 0;
        }

        h3 {
          font-weight: 400;
          font-size: 12px;
          margin: 0;
          margin-bottom: 15px;
        }
      }
    }
  }

  .flex_row_reverse {
    display: flex;
    flex-direction: row-reverse;
  }

  .dashed_bottom {
    border-bottom: 2px dashed #1f1f1f;
  }

  #text_bold {
    p {
      font-weight: 700;
      font-size: 13px;
    }
  }

  .price_and_date {
    border-top: 1px dashed #1f1f1f;

    p {
      font-weight: 700;
      font-size: 15px;
    }

    .unit_price {
      p {
        font-weight: 400;
      }
    }

    .price {
      border-bottom: 2px solid #1f1f1f;
    }

    h5 {
      margin-top: 10px;
      text-align: center;
      font-size: 14px;
    }
  }
}
