#admin_dashboard {
  #page_heading {
    a {
      text-decoration: none;
      color: $site-blue;
    }
  }

  header {
    background-color: $site_gray;
    box-shadow: none;
    color: black;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    #notification_icon {
      margin: 0 15px 0 0;

      &:hover {
        background-color: white !important;
        box-shadow: 4px 4px 60px 5px rgba(0, 0, 0, 0.07);

        border-radius: 50%;
        width: 50px;
        height: 50px;
      }
    }
  }

  main {
    background-color: white;
    padding: 0;
  }

  #each_admin_side_menu {
    .MuiAccordion-root {
      background-color: $site-blue;
      color: white;
      box-shadow: none;

      .MuiCollapse-entered {
        background-color: rgba(246, 246, 246, 0.15);

        .MuiPaper-root {
          background-color: rgba(246, 246, 246, 0.15);
        }
      }
    }
  }

  .MuiList-root,
  #admin_side_menu_header {
    background-color: $site-blue;
    color: white;

    .MuiSvgIcon-root {
      color: white;
    }
  }

  #admin_side_menu_header {
    padding-top: 15%;
    padding-bottom: 15%;

    a{
      color: white;
      text-decoration: none;
    }
  }

  .MuiDrawer-paperAnchorDockedLeft {
    border: 0;
  }

  #dashboard-body-content {
    .MuiTypography-h4 {
      color: rgba(48, 48, 48, 0.8);
      font-weight: 500;
    }

    .MuiTypography-paragraph {
      color: rgba(31, 31, 31, 0.7);
      font-weight: 300;
    }

    #welcome_image {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
}
