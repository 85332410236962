#add_to_cart {
    box-shadow: -4px -4px 55px 3px rgba(0, 0, 0, 0.05), 4px 4px 55px 3px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding-bottom: 20px;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 0;

    #add_to_cart_header {
        background-color: $site_blue;
        padding: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h3 {
            color: white;
            margin: 0;

            &:last-child{
                cursor: pointer;

                &:hover{
                border-bottom: 1px solid white;
            }
            }
        }
    }

    #each_cart_meal,
    #add_to_cart_customer {
        box-shadow: -4px -4px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        margin-bottom: 15px;
    }

    

    #single_search_option {
        margin-top: 0;

        #see_pending_requests {
            margin-top: 0;
            margin-left: 25px;
        }
    }

    #add_to_cart_total {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 20px 0;
        padding: 0 30px;
        background-color: rgba(246, 246, 246, 0.7);

        p {
            font-weight: bold;
            font-size: 14px;
            color: #303030;
        }
    }
}

.add_meal_manage_icons {
    font-size: 1.2rem;
}

#edit_cart_item {

    button {
        background-color: white;
        color: $site_blue
    }
}

#add_to_cart_customer {
    padding: 20px 0 10px 0;
    letter-spacing: 0.04em;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    span {
        cursor: pointer;
    }

    p {
        font-weight: 300;
        font-size: 9px;
        color: $site_blue;
    }

    #order_for_friend_wrapper {
        button {
            color: $site_blue;
        }

        header {
            padding: 0;
            background-color: white;
        }

        #remove_friend {
            display: flex;
            flex-direction: column;
            justify-content: center;
            cursor: pointer;
            padding-left: 20px;

            p {
                font-size: 1rem;
                margin: 0;

                &:hover{
                    font-weight: bolder;
                }
            }
        }
    }

    #add_customer_wrapper {
        button {
            color: white;
            background-color: $site_blue;

            &:hover {
                color: $site_blue;
                background-color: $site_gray;
            }
        }
    }

    button {
        span {
            margin-left: 5px;
        }

        font-size: 13px;
        border: 0.5px solid $site_blue;
        box-sizing: border-box;
        border-radius: 4px;
    }
}

#add_to_cart_btns {
    .form-btn {
        margin: 0;

        button {
            font-size: 1rem;
            border: 1px solid $site_blue;
            border-radius: 3px;
        }
    }

    #save_btn {
        color: $site_blue;
        background-color: white;

        &:hover{
            background-color: $site_gray;
        }
    }
}

#add_to_cart_meals {
    overflow: auto;
    height: 250px;
    padding-top: 20px;
}

#each_cart_meal {
    font-size: 12px;

    #qty {
        p {
            background-color: $site_blue;
            border-radius: 50%;
            color: white;
            text-align: center;
            margin: 10px 15px;
        }
    }

    #price {
        color: $site_blue;
        font-weight: bold;
        padding-right: 5px;
    }

    #view_more_btn {
        margin-top: 5px;
    }
}

#each_cart_meal_details {
    padding: 0 10px 20px;

    #each_cart_meal_btns {
        text-align: center;

        button {
            padding: 10px 25px;
            border: 1px solid rgba(0, 0, 0, 0.1);

            span {
                display: block;
                font-size: 12px;
            }
        }
    }
}