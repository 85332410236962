#select_MT {}

.MuiDialogTitle-root {
    background: #F6F6F6;
    border: 1px solid #C4C4C4;
}

#select_MT_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    aside {
        font-family: $roboto;
        color: $site_blue;

        p {
            margin: 0;
            font-size: 1.2rem;

            span {
                &:first-child {
                    color: $site_red;
                    font-weight: 700;
                    cursor: pointer;
                }
            }
        }

        &:nth-child(2){
            color: $site_red;
        }

        &:first-child {
            font-weight: 300;
            font-size: 18px;

            display: flex;
            flex-direction: row;
            align-items: center;

            span {
                margin-right: 5px;
            }
        }

        &:last-child {
            button {
                border: 1px solid rgba(17, 97, 123, 0.5);
                border-radius: 4px;
                color: $site_blue;
                font-weight: bold;
                font-size: 15px;
                margin-left: 5px;

                &:last-child {
                    background-color: $site_red;
                    border: 1px solid $site_red;
                    color: white;

                    &:hover {
                        background-color: $site_gray;
                        color: $site_red;
                    }
                }
            }
        }
    }
}

#select_mt_body {
    header {
        box-shadow: 0px 4px 0px 0px rgba(229, 229, 229, 0.5);

        #add_mp_search{
            button{
                border: 1px solid $site_blue;
                color: $site_blue;
            }
        }

        .MuiOutlinedInput-input {
            padding: 10px;
        }

        #view_selected_btn {
            button {
                background-color: $site_blue;
                border-radius: 4px;
                color: white;

                .MuiBadge-badge{
                    background-color: $site_red;
                }

                &:hover {
                    background-color: $site_gray;
                    color: $site_blue;
                    border: 1px solid $site_blue;
                }
            }
        }

        #selected_date_ranges {
            margin: 10px 0;
        }

        #btns {
            #first_btn {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;

                span {
                    button {
                        background-color: white;
                        color: $site_blue;
                    }
                }
            }

            #last_btn {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
            }

            #first_btn,
            #last_btn {
                button {
                    background-color: $site_blue;
                    border-radius: 4px;
                    color: white;

                    &:hover {
                        background-color: $site_gray;
                        color: $site_blue;
                        border: 1px solid $site_blue;
                    }
                }
            }
        }
    }

    #select_menu_item_checkbox {
        margin: 30px 0;

        height: 600px;
        overflow: scroll;

        #menu_item {
            margin-bottom: 20px;

            section {
                width: 95%;
                margin: auto;

                &:first-child {
                    position: sticky;
                    z-index: 2;

                    .container {
                        display: block;
                        position: relative;
                        cursor: pointer;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                    }

                    /* Hide the browser's default checkbox */
                    .container input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                    }

                    /* Create a custom checkbox */
                    .checkmark {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 25px;
                        width: 25px;
                        background-color: #eee;
                    }

                    /* On mouse-over, add a grey background color */
                    .container:hover input~.checkmark {
                        background-color: #ccc;
                    }

                    /* When the checkbox is checked, add a blue background */
                    .container input:checked~.checkmark {
                        background-color: $site_blue;
                    }

                    /* Create the checkmark/indicator (hidden when not checked) */
                    .checkmark:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }

                    /* Show the checkmark when checked */
                    .container input:checked~.checkmark:after {
                        display: block;
                    }

                    /* Style the checkmark/indicator */
                    .container .checkmark:after {
                        left: 9px;
                        width: 10px;
                        height: 20px;
                        border: solid white;
                        border-width: 0 3px 3px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }

                }

                &:last-child {
                    margin-top: 0px;

                    .MuiCard-root {
                        box-shadow: none;
                        background: #F6F6F6;
                        border: 1px solid rgba(0, 0, 0, 0.3);
                        border-radius: 3px;
                    }

                    .MuiCardContent-root {
                        padding: 5px;
                    }

                    .MuiCardActions-root {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;

                        button {
                            font-weight: 400;
                            font-size: 12px;
                            color: #1F1F1F;
                            border: 1px solid rgba(0, 0, 0, 0.2);
                            border-radius: 4px;
                        }
                    }
                }
            }
        }
    }

}