#menu_plan_calender {
    h2 {
        text-align: center;
    }

    #create_menu_plan_btn {
        button {
            background-color: white;
            color: $site_blue;
            padding: 10px 20px;
            border: 1px solid $site_blue;

            &:hover {
                background-color: $site_blue;
                color: white;
            }
        }
    }
}