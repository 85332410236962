#add_edit_inventory {

    a,
    button {
        text-transform: capitalize;
        color: $site_blue;
        border: 1px solid rgba(17, 97, 123, 0.1);
        border-radius: 8px;
        background-color: white;
        cursor: pointer;
        font-size: 0.8rem;
        

        &:hover {
            box-shadow: 4px 4px 22px 2px rgba(17, 97, 123, 0.05);
        }
    }

    button{
        margin-right: 5px;
    }

    a{
        text-decoration: none;
        padding: 0 8px;
        margin-left: 5px;
    }
}

#add_inventory_qty_form {
    h4 {
        margin-top: 20%;
        margin-bottom: 0;
        color: $site_blue;

        &:first-child {
            margin-top: 5%;
        }
    }

    p {
        margin-top: 0;
        color: rgba(48, 48, 48, 0.65);
    }

    p,
    h4 {
        font-weight: 400;
    }

    .form-btn {
        margin-top: 20%;
    }
}