#create_menu_plan_navigation{
    margin: 2% 0 4% 0;

    button{
        font-size: 1rem;
        padding-left: 0;
        border: 1px solid rgba(0, 0, 0, 0.1);

        span{
            margin-left: 10px;
        }

        &:hover{
            box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
        }
    }
}