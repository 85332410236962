#order_settings {

    #upper_section {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        padding: 0 2% 0 3%;
    }

    .form-btn {
        margin-top: 15%;
    }

    #vertical_rule {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        height: auto;
        margin: 5% 8%;
    }

    #processing_dispatch_ready_time,
    #waiting_time {
        margin: 30px 0;
    }


    #dispatch_ready_time {
        margin-top: 30%;
    }

    #waiting_time {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    h3 {
        color: $site_blue;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.5rem;
        letter-spacing: 0.04em;
    }

    p {
        font-weight: 300;
        font-size: 0.8rem;
        line-height: 1rem;
    }
}