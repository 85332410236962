#pos_checkout_popup {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;

    .checkout_prices {
        border: 1px solid rgba(0, 0, 0, 0.05);
        box-shadow: 2px 2px 50px 15px rgba(0, 0, 0, 0.03);
        border-radius: 3px;
    }

    .payment_methods {
        p {
            font-weight: 500;
            color: #1F1F1F;
        }

        button {
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 5px;
            color: $site_blue;
            padding: 2% 6%;

            font-weight: 300;
        }

        .selected_payment_method{
            background-color: $site_blue;
            color: white;
            font-weight: 500;
        }

        div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 20px;

            &:nth-child(3) {
                button {
                    color: #1F1F1F;
                }
            }
        }
    }

    #bank_details,
    #pos_ref {
        h4 {
            font-weight: 500;
            font-size: 18px;
            color: $site_blue;
        }
    }

    #bank_details {
        #bank_options {
            padding: 10px 10px;
            border: 1px solid rgba(0, 0, 0, 0.15);

            &:hover{
                background-color: $site_gray;
                cursor: pointer;
            }

            p,
            h3 {
                margin: 5px 10px;
                color: #1F1F1F;
            }

            p {

                font-weight: 300;
                font-size: 11px;

            }

            h3 {
                font-weight: normal;
                font-size: 12px;
            }
        }
    }

    #checkout_due_date,
    .payment_methods,
    #checkout_enter_amount {
        margin: 50px 0;

        p {
            font-weight: 500;
            color: #1F1F1F;
        }
    }
}

#add_bank_details{
    margin-top: 10px;
    
    button{
        span{
            margin-right: 5px;
            margin-top: 3.5px;
        }

        &:hover{
            box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
        }

        text-transform: capitalize;
        color: $site_blue;
    }
}

#checkout_modal{
    .MuiMenuItem-root{
        color: $site_blue;
        border: 1px solid $site_blue;
        
    }
}