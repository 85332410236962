@import "./ResponsiveScreens/include_media.scss";

html {
  scroll-behavior: smooth;
}

body {
  @import "./helpers.scss";

  background-color: white !important;

  @import "./admin/login.scss";

  @import "./admin/admin_dashboard.scss";

  @import "./admin/create_inventory.scss";

  @import "./admin/create_menu_item.scss";

  @import "./admin/add_category_group.scss";

  @import "./admin/create_menu_plan.scss";

  @import "./admin/menu_plan_calender.scss";

  @import "./admin/create_menu_plan_navigation.scss";

  @import "./admin/add_menu_plan_category_popup.scss";

  @import "./admin/add_meal_input_popup.scss";

  @import "./admin/profile_settings.scss";

  @import "./admin/branch_setup.scss";

  @import "./admin/order_settings.scss";

  @import "./admin/schedule_settings.scss";

  @import "./admin/create_staff.scss";

  @import "./admin/create_role.scss";

  @import "./admin/create_promotion.scss";

  @import "./admin/create_voucher.scss";

  @import "./admin/manage_inventory.scss";

  @import "./admin/edit_inventory.scss";

  @import "./admin/view_an_order.scss";

  @import "./admin/delivery_area.scss";

  @import "./globalAsset/mouse-over-popover.scss";

  @import "./admin/menu_plan_time_limit.scss";

  @import "./admin/pos_order.scss";

  @import "./admin/add_to_cart.scss";

  @import "./admin/pos_customer.scss";

  @import "./admin/pos_menu_item_popup.scss";

  @import "./admin/pos_checkout_popup.scss";

  @import "./admin/new_order.scss";

  @import "./admin/kitchen_slips.scss";

  @import "./admin/receipt.scss";

  @import "./admin/mp_order_frame.scss";

  @import "./admin/view_menu_items.scss";

  @import "./admin/add_mp_details";

  @import "./admin/select_MT.scss";

  @import "./admin/customer_mp.scss";

  @import "./admin/customer_mp_preview.scss";

  @import "./admin/view_update_customer_recipe_details.scss";

  @import "./admin/survey_details.scss";

  @import "./admin/notification.scss";

  @import "./admin/payment.scss";

  // responsive
  @import "./ResponsiveScreens/phone.scss";

  @import "./ResponsiveScreens/tab.scss";

  @import "./ResponsiveScreens/tab_lg.scss";

  @import "./ResponsiveScreens/desktop.scss";
}
