#admin_login {
    height: 100vh;

    #welcome_message {
        display: flex;
        flex-direction: column;
        justify-content: center;

        background-color: $site-blue;
        color: white;
        text-align: center;

        h1 {
            font-size: 5rem;
            margin-bottom: 0;
        }

        p {
            font-size: 2rem;
            margin-top: 0;
            font-weight: 300;
        }
    }

    #login_form {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 5%;

        label {
            font-size: 1.3rem;
        }

        input {
            margin-bottom: 2%;
            margin-top: 1%;
            font-size: 1.3rem;
        }

        .MuiTextField-root {
            margin-bottom: 10%;
        }

        .form-btn {
            margin-top: 5%;
        }
    }

    #login_redirect {
        font-size: 20px;
        text-transform: uppercase;

        a,
        button {
            border-radius: 15px;
            outline: 1px solid $site-blue;
            border: 2px solid white;
            background-color: white;
            color: $site-blue;
            text-decoration: none;
            font-size: 20px;
            font-weight: bold;

            &:hover {
                background-color: $site_gray;
                color: $site-blue;
            }

            &:last-child {
                color: $site_red;

                &:hover {
                    background-color: $site_red;
                    color: white;
                }
            }
        }
    }
}