#pos_order {
    letter-spacing: 0.04em;

    header,
    main {
        padding: 0 25px;
    }

    #pos_menu_items {
        height: 500px;
        overflow: auto;
    }

    header {
        position: -webkit-sticky;
        /* Safari */
        position: sticky;
        padding-top: 10px;
        top: 64px;
        z-index: 1;

        #top_pos_btns {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            button {
                &:hover {
                    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
                    border-radius: 4px;
                }
            }
        }
    }

    #top_pos_btns {
        margin-bottom: 50px;

        #show_controls_btn{
            border: 1px solid rgba(17, 97, 123, 0.5);
        }

        button {
            border: 1px solid rgba(17, 97, 123, 0.5);
            box-sizing: border-box;
            margin-right: 10px;
            color: #11617B;
            font-size: 13px;

            span {
                padding-left: 5px;
                padding-top: 3px;
            }

            &:last-child {
                border: 0;

                span {
                    padding-right: 5px;
                    padding-top: 3px;
                }
            }
        }
    }

    #show_cart_btn {
        text-align: right;
        
        .MuiBadge-root {
            box-sizing: border-box;
            color: $site_blue;
            font-weight: 500;
            font-size: 13px;
            border: 0;
            padding: 5px 10px;
            text-transform: uppercase;
            cursor: pointer;

            &:hover {
                box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
                border-radius: 4px;
            }

            span {
                background-color: $site_red;
            }
        }
    }

    #pos_filter_drawer {
        button {
            span {
                margin-right: 5px;
            }

            &:hover {
                box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
                border-radius: 4px;
            }
        }
    }
}

#see_pending_requests {
    letter-spacing: 0.04em;
    margin-top: 20px;

    .MuiBadge-root {
        font-weight: 500;
        font-size: 13px;
        color: $site_blue;
        text-transform: uppercase;
        cursor: pointer;
        padding: 5px 10px;
        &:hover {
            box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
        }

        span{
            background-color: $site_red;
        }
    }
}

.pending_request_body {
    &:hover {
        background-color: $site_gray;
    }

    box-shadow: 4px 4px 16px 1px rgba(17, 97, 123, 0.1);
    border-radius: 4px;
    padding: 2px 10px;
    margin-top: 15px;

    font-weight: 300;
    font-size: 11px;
    letter-spacing: 0.04em;

    color: #303030;

    section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;

        p, h3 {
            margin: 0 0 5px 0;
        }

        #phone {
            color: $site_blue;
        }
    }
}

#pos_menu_items {
    .MuiPaper-root {
        margin: 5px;

        .MuiCardActions-root {
            display: flex;
            flex-direction: row;
            justify-content: center;

            button {
                box-shadow: 4px 4px 20px 5px rgba(0, 0, 0, 0.05);
                border-radius: 4px;
                color: black;
            }
        }
    }
}

#pos_filter {
    button {
        background-color: $site_blue;
        border: 3px solid $site_blue;
        color: white;
        padding: 0;

        &:hover {
            background-color: $site_gray;
            color: $site_blue;
        }
    }
}