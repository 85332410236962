#add_meal_input_popup {
    button {
        margin-left: 1%;
    }
}

    #single_search_option {
        box-shadow: -4px -4px 4px rgba(0, 0, 0, 0.05), 4px 4px 4px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        margin-top: 5%;
    }

    #search_option_img {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    #search_option_text {
        color: black;

        p {
            &:last-child {
                color: $site_blue;
            }
        }
    }

    #selected_category {
        font-style: italic;
        letter-spacing: 0.04em;
        color: black;
        font-weight: 300;

        span {
            font-weight: 700;
        }
    }