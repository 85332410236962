.receipt {
    font-family: $IBM_condensed;
    width: 300px;
    letter-spacing: 0.04em;
    padding: 0 5px;

    h1, h2, h3{
        font-weight: 700;
        font-family: $roboto_mono;
    }

    h1,
    h3,
    h2 {
        font-size: 20px;
        color: black;
        text-align: center;
    }

    h1 {
        font-size: 30px;
        color: black;
    }

    #delivery_address {
        margin: 30px 5px 5px 5px;
        text-align: center;
        padding-bottom: 30px;

        h3 {
            margin: 0;
            text-align: center;
        }

        border-bottom: 2px dashed #1f1f1f;

    }

    p {
        margin-bottom: 0;
        font-size: 10px;
        color: black;
        font-weight: 400;
    }

    h3 {
        font-size: 12px;
        color: black;
        display: block;
        margin-bottom: 0;

        span {
            color: black;
        }
    }

    .waiter {
        margin-top: 10px;
        border-bottom: 2px dashed #1f1f1f;
        font-weight: 700;
    }

    .cart_item_details {
        border-bottom: 2px dashed #1f1f1f;
        padding: 20px 0;

        #qty {
            p {
                font-weight: 700;
            }
        }
    }

    .price_details {
        margin: 15px 0;
        padding: 0 50px;

        .total {
            margin: 10px 0;
            font-weight: 700;
        }
    }

    .payment_details {
        border-bottom: 2px dashed #1f1f1f;
        padding-bottom: 20px;
    }

    .other_details {
        padding: 15px 0;
        text-align: center;
    }

    .flex_row_reverse {
        display: flex;
        flex-direction: row-reverse;
    }

}