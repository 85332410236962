#view_mwnu_items {
    .choosen_range {
        p {
            margin: 0;
        }
    }

    .top_filter {
        padding-top: 15px;
    }

    #meal_items {
        margin-top: 30px;
        font-family: $roboto;
        letter-spacing: 0.04em;

        .MuiGrid-root {
            padding: 0 20px 20px 20px;

            .MuiPaper-root {
                box-shadow: none;
            }

            .MuiCard-root {
                background-color: $site_gray;
                border: 1px solid rgba(0, 0, 0, 0.3);
                border-radius: 5px;

                p {
                    font-weight: 300;
                    font-size: 12px;
                    color: #000000;
                }

                .MuiCardActions-root {
                    button {
                        border: 1px solid rgba(17, 97, 123, 0.45);
                        border-radius: 3px;
                        color: $site_blue;
                        text-transform: capitalize;
                        padding: 0;
                    }
                }
            }
        }
    }
}