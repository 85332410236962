.customer_mp {
  #meal_items {
    .MuiGrid-root {
      padding: 5px !important;
    }
  }
}

.customer_meal_items {
  padding: 10px !important;
}

#plan_title_name {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  span {
    color: black;
  }
}

#plan_modal_close {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:last-child{
    font-size: 20px;
  }

  button{
    border: 1px solid $site_blue;
    color: $site_blue;
    margin-right: 20px;
  }

  p{
    margin: 0;
  }
}

.MuiDialogTitle-root {
  background-color: white;
  border: none;
  outline: none;
}

.MuiDialogContent-dividers {
  border-top: none;
}

.item_popup_header,
#adjust_meal_item_details,
#time_qty_adjustment,
#selected_time {
  font-family: $roboto;
  color: #1f1f1f;
}

.item_popup_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 300;

  span {
    font-weight: 700;
  }

  #cancel_icon {
    color: $site_red;
    cursor: pointer;
  }
}

#adjust_meal_item_details {
  #meal_title {
    h1,
    p {
      margin: 0;
      font-size: 18px;
    }

    h1 {
      font-weight: 800;
    }
    p {
      font-weight: 400;
    }
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  .MuiAccordion-root {
    margin-top: 30px;
  }

  .MuiAccordionSummary-root {
    background: #f8f8f8;

    p {
      font-weight: 700;
      font-size: 20px;
      color: #363434;
      text-transform: uppercase;
    }
  }

  #addons_preferences_content {
    font-weight: 300;
    font-size: 18px;

    .MuiFormGroup-root {
      margin-top: 10px;

      span {
        font-size: 18px;
      }
    }

    p {
      margin: 0;
      margin-top: 20px;
    }

    #qty {
      p {
        color: #838383;
        cursor: pointer;
      }

      span {
        cursor: text;
        padding: 5px 10px;
        margin: 0 15px;
        background-color: $site_gray;
      }
    }
  }

  #modify {
    margin-top: 30px;
    border-bottom: 10px solid $site_gray;
    padding-bottom: 40px;

    header {
      background: #f8f8f8;
      padding: 15px 0 15px 15px;
      font-size: 20px;
      font-weight: 700;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    main {
      padding: 20px;

      .MuiTextField-root {
        background-color: $site_gray;
        border: none;
        outline: none;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border: 0 !important;
      }

      .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border: 0 !important;
      }
    }

    #price {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-weight: 300;
      font-size: 18px;
      color: #1f1f1f;
      margin: 0 20px;

      p {
        margin: 0;
      }
    }
  }

  #select_days {
    header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      h3,
      p {
        margin: 0;
      }

      h3 {
        color: #c4c4c4;
      }

      p {
        margin-top: 5px;

        span {
          cursor: pointer;
          padding: 5px;
        }
      }
    }

    #days {
      margin: 20px 0;
      border-bottom: 1px solid #c4c4c4;
      padding-bottom: 10px;

      p {
        margin: 0;
        font-size: 12;
        color: #c4c4c4;

        &:first-child {
          color: #949494;
        }
      }

      #day {
        text-align: center;
        border: 2px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 7px;
        cursor: pointer;

        &:hover {
          background-color: $site_gray;
          color: black;
        }
      }
    }
  }
}

#time_qty_adjustment {
  header {
    p {
      font-weight: 300;
      font-size: 18px;
    }
  }

  #time_qty {
    .MuiGrid-root {
      background-color: #f8f8f8;
      margin-bottom: 10px;
      border-radius: 12px;
    }

    #qty {
      padding: 0 80px;

      p {
        margin: 0;
        padding: 5px 0;
        font-size: 20px;
        background: #c4c4c4;
        border-radius: 100px;
        color: #838383;
        cursor: pointer;

        span {
          margin: 0 20px;
          background: #efefef;
          border-radius: 6px;
          padding: 0 10px;
          cursor: text;
        }
      }
    }
  }
}

#selected_time {
  header {
    font-weight: bold;
    font-size: 20px;
    color: #c4c4c4;
    background-color: #f8f8f8;
    padding: 10px 10px;
  }

  main {
    padding: 10px 20px;

    h3 {
      font-weight: 800;
      font-size: 16px;
      color: #949494;

      &:last-child{
        cursor: pointer;

        &:hover{
          color: $site_red;
        }
      }
    }

    #selected_time_date_heading{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    section {
      background: #f6f6f6;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 7px;
      padding: 10px;

      p {
        margin: 0;
        font-weight: 300;
        font-size: 16px;

        span {
          font-weight: 700;
        }
      }
    }
  }
}

#search_customer {
  button {
    background-color: transparent;
    border: none;
  }
}

.selectedFrom {
  background-color: yellow;
}
