#preview_customer_mp {
  font-family: $roboto;
  border: 1px solid rgba(0, 0, 0, 0.15);

  #no_item_selected {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 100px;

    p {
      font-weight: 900;
      font-size: 36px;
      color: #e5e5e5;
      text-align: center;
    }
  }
}

#general_info {
  font-weight: 400;
  font-size: 15px;
  color: #303030;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  .text_key {
    p {
      font-weight: 300;
    }
  }

  .total_price {
    font-weight: 900;
    font-size: 18px;
    color: #1f1f1f;
  }

  p {
    margin: 0;
    margin-bottom: 10px;
  }
}

#preview_content {
  padding: 20px;
  color: #000000;

  #each_date {
    border: 3px solid #f6f6f6;
    border-radius: 5px;
  }

  header {
    padding: 10px;

    #each_day_header {
      display: flex;
      flex-direction: row;
      align-items: center;

      h1,
      p {
        color: #000000;
        margin: 0;
      }

      h1 {
        font-weight: 500;
        font-size: 30px;
      }

      p {
        font-weight: 100;
        font-size: 18px;
        margin-left: 10px;
      }
    }
  }

  main {
    padding: 10px;
    border-top: 1px solid #e5e5e5;

    h3,
    h4,
    p {
      margin: 0;
    }

    h3 {
      font-weight: bold;
      font-size: 14px;
      margin: 10px 0 0 10px;
    }

    #each_preview_content_dates {
      padding: 10px;
    }

    #preview_content_dates {
      background: #f8f8f8;
      border: 1px solid #e5e5e5;
      box-shadow: 4px 4px 27px 6px #f6f6f6;
      border-radius: 4px;
      margin: 15px 0;
      padding: 10px;

      #price {
        border-bottom: 3px solid #e5e5e5;

        h1 {
          margin: 0;

          font-weight: 900;
          font-size: 18px;
          color: #1f1f1f;
        }
      }

      #title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 20px;

        #cancel_icon {
          cursor: pointer;

          &:hover {
            color: $site_red;
          }
        }

        p {
          font-size: 14px;
          line-height: 23px;

          span {
            color: $site_blue;
            font-weight: 900;
            font-size: 16px;

            &:last-child {
              color: #c4c4c4;
              font-size: 12px;
            }
          }
        }
      }

      #instructions {
        margin: 15px 0;
        h4 {
          font-weight: 500;
          font-size: 14px;
          color: #303030;
          margin-bottom: 5px;
        }

        p {
          font-weight: bold;
          font-size: 12px;
          color: #c4c4c4;
        }
      }

      #edit_btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $site_blue;
        font-weight: 800;
        font-size: 14px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }

        p {
          padding-left: 5px;
        }
      }
    }
  }
}

.preview_popup {
  #popup_btn {
    padding: 10px;
    cursor: pointer;
    color: $site_blue;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .MuiBadge-badge {
      background-color: $site_red;
    }

    margin-left: 5px;
    font-weight: 500;
  }
}
