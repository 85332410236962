#profile_settings {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    letter-spacing: 0.04em;

    #profile_details {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);

        #profile_image {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        #user_name {
            font-size: 1.3rem;
            color: $site_blue;
        }
    }

    #phone_role {
        margin-left: 2%;
        color: #C4C4C4;

        h4 {
            font-weight: 700;
        }
    }

    #user_password {
        margin-left: 2%;

        p {
            color: $site_blue;
            cursor: pointer;
        }
    }

    #change_password {
        section {
            margin: 10% 4%;
        }
    }

    #permissions {
        background-color: rgba(246, 246, 246, 0.5);
        padding-left: 2%;

        h4 {
            font-weight: 500;
            font-size: 1.5rem;
        }

        p {
            font-weight: 300;
            font-size: 1rem;
        }
    }

    #profile_buttons {
        margin: 10% 0;

        #profile_update_btn {
            padding-left: 5%;

            button {
                background-color: $site_blue;
                color: white;
                padding: 10px 20px;

                &:hover {
                    background-color: white;
                    color: $site_blue;
                    border: 1px solid $site_blue;
                }
            }
        }

        #profile_cancel_btn {
            padding-right: 5%;
            display: flex;
            flex-direction: row-reverse;

            button {
                background-color: white;
                color: $site_blue;
                padding: 10px 20px;
                border: 1px solid $site_blue;

                &:hover {
                    background-color: $site_blue;
                    color: white;
                }
            }
        }

    }
}