#add_category_group{
    p{
        cursor: pointer;
        color: $site-blue;
        font-weight: 700;
        font-size: 1.1rem;

        span{
            margin-left: 5%;
            padding-top: 0;
        }

        &:hover{
            text-decoration: underline;
        }
    }
}