#schedule_settings {
    letter-spacing: 0.04em;

    .heading {
        color: $site_blue;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.5rem;
        margin-bottom: 5px;
        margin-top: 30px;
        padding-left: 20px;
    }

    .right_heading {
        padding-left: 90px;
    }

    .input_section {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        margin-top: 15px;

        .vertical_rule {
            margin: 10px 65px 10px 100px;
        }

        p {
            color: $site_blue;
            margin: 0;
            font-size: 0.8rem;

            font-weight: 500;
            line-height: 1rem;
            letter-spacing: 0.04em;
        }
    }

    .left_section,
    .right_section {
        padding: 30px 0 30px 20px;
    }

    .add_more_limits_btn {
        display: flex;
        flex-direction: row-reverse;

        button {
            color: $site_blue;

            span {
                padding-right: 7px;
                margin-top: 3px;
            }

            &:hover {
                border: 1px solid rgba(17, 97, 123, 0.15);
                box-shadow: 4px 4px 31px 2px rgba(17, 97, 123, 0.08);
                border-radius: 4px;
                background-color: white;
            }
        }
    }

    .form-btn {
        margin-top: 10%;
    }
}