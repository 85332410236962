#create_menu_item {
    .switch_input {
        margin-left: -4%;
    }

    #discount_input {
        .MuiTextField-root {
            margin-top: 10%;
        }
    }

    .form_right_section,
    .form_left_section,
    .full_width_section {
        margin-bottom: 5%;
    }

    .form-table {
        margin-top: 20px;
        width: 100%;

        .remove-btn {
            button {
                background-color: transparent;
                border: none;
                color: rgba(0, 0, 0, 0.4);

                &:hover {
                    color: red;
                }
            }
        }

        .sub_price {
            color: $site_blue;
            font-size: 0.7rem;
        }

        .MuiTableContainer-root {
            margin-bottom: 20px;
        }

        #qty {
            span {
                margin: 0 5%;
                font-weight: 500;
            }

            .span_btn {
                background-color: #C4C4C4;
                color: black;
                border-radius: 5px;
                padding: 4px 2px 1px 2px;
                cursor: pointer;

                &:hover {
                    background-color: transparent;
                    border: 1px solid black;
                }
            }
        }
    }

    .form-dropdown {
        margin-top: 0;

        h5 {
            margin-left: 5%;
            font-size: 1rem;
            font-style: italic;
        }

        p {
            span {
                margin-left: 1.5%;
            }
        }

        #addons_ategory_section {
            margin-left: 5%;
        }

        .MuiFormControl-root {
            margin: 0 0 5% 5%;
        }

        .MuiFormControlLabel-root{
            margin-top: 15px;
        }
    }

    #menu_category_input {
        .form_left_section {
            margin-bottom: 0;
        }

        .MuiGrid-root {
            margin-bottom: 0;
        }
    }

    #add_category_popup {
        button {
            padding: 8% 20% 14% 18%;
        }
    }

    .preference_price {
        color: $site_blue;
        font-size: 0.7rem;
        padding: 0 0 0 30px;
    }

    #inventory_notice{
        text-align: center;
        color: red;
    }

    #discount_warning{
        color: red;
        margin-left: 20px;
        font-weight: bolder;
    }
}