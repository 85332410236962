#pos_menu_item_popup {
    letter-spacing: 0.04em;

    #menu_item_addons {
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 4px 4px 7px 1px rgba(17, 97, 123, 0.1);
        border-radius: 4px;
        padding: 20px;
        margin-bottom: 20px;

        h3 {
            margin: 0;
        }

        #menu_item_addons_list {
            margin: 10px;
            box-shadow: -4px -4px 4px rgba(0, 0, 0, 0.04), 4px 4px 4px rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            padding: 10px;
            width: 100%;
            height: 300px;
            overflow: auto;
        }

        #menu_item_selected_addons {
            aside {
                height: 300px;
                overflow: auto;
            }

            #view_selected_addons {
                font-weight: 300;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.3);
                text-align: center;
                border: 3px dashed rgba(0, 0, 0, 0.3);
                border-radius: 0 50px 0 50px;
                padding: 0 20px;
                margin-bottom: 10px;
            }

            #qty_btns {
                p {
                    margin-top: 0;
                }
            }
        }
    }

    .MuiFormControlLabel-root {
        font-size: 12px;

        #price {
            color: rgba(48, 48, 48, 0.7);
            font-family: $roboto;
            text-align: right;
        }

        #label {
            color: #303030;
            font-family: $roboto;
            font-weight: bold;
        }

    }

    #menu_item_preference {
        margin: 30px 15px;

        #menu_item_preference_list {
            height: 300px;
            overflow: auto;
        }

        .MuiFormLabel-root{
            top: 0;
            position: sticky;
            background-color: white;
            z-index: 1000;
            width: 100%;
            padding: 15px 0;
        }

        .MuiFormControl-root {
            width: 100%;
        }

        #special_instruction {
            margin-left: 50px;
        }

        #menu_item_preference_list {
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 3px;
            padding: 0 10px;
        }
    }
}

.price_section {
    section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 12px;
        padding: 0 10px;
        color: rgba(31, 31, 31, 0.35);

        &:last-child {
            box-shadow: 4px 4px 24px -2px rgba(17, 97, 123, 0.1);
            border-radius: 4px;
            margin-top: 15px;
            padding: 0 10px 10px 10px;
            color: black;
            font-weight: bold;

            p {
                &:last-child {
                    color: $site-blue;
                }
            }
        }

        p {
            margin-bottom: 0;
        }
    }

    h4 {
        color: black;
        margin-bottom: 0;
    }
}

#qty_btns,
.adjust_menu_item_qty {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    font-weight: 300;
    font-size: 13px;
    color: #303030;

    p {
        margin-bottom: 0;
        margin-top: 0;
    }

    button {
        margin: 0 15px;
        border: none;
        background: #F6F6F6;
        border-radius: 3px;
        cursor: pointer;

        &:hover {
            background-color: $site-blue;
            color: white;
            font-weight: bold;
        }
    }
}

.pos_vertical_rule {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    height: auto;
    margin: 0 20px 0 40px;
}

#main_adjust_qty {
    margin-top: 50px;

    #qty_btns {
        margin: 15px 0;
    }
}

.cart_item_header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    section{
        color: $site-blue;

        &:nth-child(2){
            color: $site_red;
        }
        
        &:last-child{
            cursor: pointer;

            &:hover{
                color: $site_red;
            }
        }
    }
}