.table_page_frame {
    .MuiBox-root {
        padding: 0;
        padding-top: 30px;
    }

    .main_table_frame {
        .MuiPaper-root {
            box-shadow: none;
            border: 1px solid rgba(0, 0, 0, 0.15);
        }

        .MuiTableRow-root{
            cursor: pointer;
            text-align: center;
        }

        th {
            background-color: #f6f6f6;
            text-align: center;

            button {
                margin-left: 22%;
                color: $site_blue;
                font-weight: bold;
                font-size: 0.9rem;
                letter-spacing: 0.02em;
                color: $site_blue;
            }
        }

        td {
            text-align: center;
            color: rgba(48, 48, 48, 0.5);

            &:first-child {
                color: $site_blue !important;
            }
        }

        tbody>tr>:nth-child(3) {
            font-weight: bold;
            letter-spacing: 0.02em;
            color: $site_blue;
        }
    }


}

.top_filter {
    margin-bottom: 10px;
    background-color: #F6F6F6;

    .choosen_range {
        border-bottom: 5px solid $site_blue;
        color: #1F1F1F;
        font-weight: bolder;

        display: flex;

        p {
            margin-left: 30px;
            span {
                background: rgba(17, 97, 123, 0.1);
                border-radius: 10px;
                padding: 5px;
                color: $site_blue;
                font-size: 0.8rem;
            }
        }
    }

    .filter_text {
        display: flex;
        flex-direction: row-reverse;
        padding-right: 5px;
        margin-top: 2px;
        color: $site_blue;
    }

    .filter_forms {
        display: flex;
        flex-direction: row;
        align-items: center;
        p{
            margin-left: 10px;
            margin-right: 5px;
        }

        .MuiFormControl-root {
            margin-right: 10px;
        }

        .MuiOutlinedInput-input {
            padding-top: 5px;
            padding-bottom: 5px;
            color: rgba(48, 48, 48, 0.5);
        }
    }
}

.pagination_btns{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: 50px;

    button{
        background-color: white !important;
        border: none !important;
        color: black !important;

        &:hover {
            box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
        }
    }
}
