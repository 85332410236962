@include media('>tablet', '<=desktop') {
    // background-color: green !important;

    // pos
    #pos_filter {
        width: 250px;

        .MuiTextField-root,
        .MuiInputBase-root {
            margin-bottom: 10px;
            width: 250px;
        }

        button {
            width: 100px;
            margin: 12px;
        }
    }
}