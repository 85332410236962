@include media('<=480px') {
    // background-color: green !important;

    // pos_menu_item_popup
    #pos_menu_item_popup {
    
        #menu_item_addons {
    
            #menu_item_addons_list {
                height: 200px;
            }
    
            #menu_item_selected_addons {
                margin-top: 10px;

                aside {
                    height: 200px;
                }
            }
        }
    
        #menu_item_preference {
            margin: 30px 0;
    
            #menu_item_preference_list {
                height: 200px;
            }
    
            #special_instruction {
                margin-left: 0;
            }
        }
    }
        
    #main_adjust_qty {
        margin-top: 20px;
    }
    
}
