#add_mp_details {
  font-family: $roboto;
  margin: auto;
  width: 95%;
  margin-bottom: 50px;

  #add_mp_details_header {
    position: -webkit-sticky;
    position: sticky;
    top: 64px;
    z-index: 5;
    background-color: white;
    padding: 20px 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0;

    h1 {
      font-weight: bold;
      font-size: 36px;
      color: $site-blue;
      margin: 0;
    }

    button {
      background-color: $site-blue;
      color: white;
      border: 1px solid $site-blue;

      &:hover {
        background-color: $site-gray;
        color: $site-blue;
      }

      span {
        margin-right: 5px;
      }
    }
  }

  #each_day {
    #each_day_header {
      display: flex;
      flex-direction: row;
      align-items: center;

      p {
        margin-left: 10px;
      }
    }

    #each_day_body {
      .MuiAccordion-root {
        box-shadow: none;
        background: #f8f8f8;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 5px;
      }

      .MuiAccordionDetails-root {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        h1 {
          font-weight: bold;
          font-size: 64px;
          color: #c4c4c4;
        }
      }

      .MuiAccordionSummary-root {
        font-weight: 700;
        font-size: 24px;
        color: $site-blue;
        text-transform: uppercase;
      }
    }
  }
}

#mp_retrieving_meals{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
   
  button{
    margin-bottom: 20px;

    &:last-child{
      background-color: transparent;
      color: $site-blue;
    }
  }
}