#view_update_customer_recipe_details {
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin: 5px 20px;
  padding-bottom: 50px;
  font-family: $roboto;

  #price,
  #update_form {
    padding: 20px;

    h4 {
      font-weight: 300;
      font-size: 24px;
      color: #1f1f1f;
      margin: 0;
      margin: 30px 0 10px 0;
    }

    .MuiFormControl-root {
      background-color: #f6f6f6;
      border-radius: 7px;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: none !important;
    }
  }

  #recipe_details {
    color: #1f1f1f;
    padding: 20px;

    #recipe_details_name {
      h3 {
        font-size: 24px;
      }
    }

    p {
      font-weight: 300;
      font-size: 24px;
      margin-bottom: 0;
      padding-left: 25px;
    }

    h3 {
      font-weight: 300;
      font-size: 15px;
      margin-top: 0;
      padding: 25px;


      background: #F6F6F6;
border-radius: 7px;
    }
  }

  #select_delivery_time {
    padding: 20px;

    #heading {
      padding: 5px 20px;
      background: #f6f6f6;
      border-radius: 7px;

      h2 {
        font-weight: bold;
        font-size: 20px;
        color: #c4c4c4;
      }
    }

    #delivery_time {
      padding-left: 10px;
      h3 {
        font-weight: 800;
        font-size: 14px;
        color: #949494;
      }

      section {
        background: #f6f6f6;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 7px;
        padding: 10px;

        p {
          font-weight: 300;
          font-size: 14px;
          color: #1f1f1f;
          margin: 0;

          span {
            font-weight: 800;
          }
        }
      }
    }

    #price {
    }
  }

  #price {
    h4 {
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: #1f1f1f;
    }

    #update_price {
      color: #1f1f1f;
      font-size: 24px;

      p,
      h3 {
        margin: 0;
      }

      p {
        font-weight: 700;
      }

      h3 {
        font-weight: 300;
      }
    }
  }

  #time_details {
    .date_time_container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-bottom: 30px;

      span {
        color: rgba(48, 48, 48, 0.75);
        margin-top: 7px;
        margin-left: 25px;

        &:first-child {
          color: rgba(48, 48, 48, 0.6);
          font-weight: bold;
        }
      }
    }

    .receipt_kitchen_container {
      button {
        background: #f6f6f6;
        border: 1px solid #c4c4c4;
        border-radius: 8px;

        font-weight: 700;
        font-size: 14px;
        color: $site_blue;

        margin-right: 30px;
      }
    }

    #until_delivery {
      margin-top: 20%;
      margin-bottom: 20%;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      #until_delivery_time {
        font-size: 3rem;
        color: #303030;
      }

      #until_delivery_text {
        font-size: 1rem;
        color: #d30000;
      }
    }

    #delivery_btn {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-top: 60px;

      button {
        padding: 10px 20px;
      }

      .btn_blue {
        button {
          background-color: $site_blue;
          color: white;
          border: 1px solid $site_blue;

          &:hover {
            background-color: white;
            color: $site_blue;
            border: 1px solid $site_blue;
          }
        }
      }

      .btn_white {
        button {
          background-color: white;
          color: $site_blue;
          border: 1px solid $site_blue;

          &:hover {
            background-color: $site_blue;
            color: white;
          }
        }
      }
    }
  }
}
