#delivery_area {
    margin-top: 20px;

    .MuiGrid-container {
        margin-bottom: 3%;
    }

    main {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        padding-left: 2%;
        padding-right: 2%;
    }

    .area {
        padding-right: 5%;
    }

    #add_more_area_btn {
        button {
            font-weight: 700;
            text-transform: capitalize;
            color: $site_blue;
            font-size: 1rem;

            span {
                margin-right: 10px;
            }
        }
    }

    .form-btn {
        margin-top: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    #disabled_btn {
        button {
            background-color: $site_gray;
        }
    }

    #added_delivery_area{
        height: 300px;
        overflow: auto;
    }
}