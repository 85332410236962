#survey_form_details {
  padding: 20px;
  p,
  h3 {
    margin: 0;
  }

  h3 {
    padding-left: 20px;
    margin-bottom: 15px;
  }
}

#survey_delivery_time_day {
  background: #f6f6f6;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 7px;
  margin: 10px 5px;
  padding: 0 10px;
  text-align: center;
}
