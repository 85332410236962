#view_an_order {
    border: 1px solid rgba(0, 0, 0, 0.15);
    letter-spacing: 0.04em;

    .vertical_rule {
        margin-top: 0;
        margin-bottom: 0;
    }

    #payment_details,
    #delivery_details,
    #order_details {
        p {
            margin-bottom: 0;
            letter-spacing: 0.04em;
            color: #303030;
        }

        .text_key {
            font-weight: 300;
        }
    }

    #payment_and_delivery {
        #payment_details {
            p {
                margin-bottom: 10px;

            }
        }

        #delivery_details {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    #payment_and_delivery,
    #order_and_time_details {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        padding: 30px;

        #time_details {
            .date_time_container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 30px;

                span {
                    color: rgba(48, 48, 48, 0.75);
                    margin-top: 7px;

                    &:first-child {
                        color: rgba(48, 48, 48, 0.6);
                    }
                }

                .form-btn {
                    margin-top: 0;

                    button {
                        font-size: 1rem;

                        .MuiButton-label {
                            color: white;
                        }

                        &:hover{
                            color: $site_blue
                        }

                        padding: 1px 10px;
                    }
                }

                .receipt_kitchen_container{
                    .MuiMenuItem-root{
                        background-color: #D30000;
                    }
                }
            }

            #until_delivery {
                margin-top: 20%;
                margin-bottom: 20%;

                #until_delivery_time {
                    font-size: 3rem;
                    color: #303030;
                }

                #until_delivery_text {
                    font-size: 1rem;
                    color: #D30000;
                    margin-top: 55px;
                }
            }

            #delivery_btn {
                display: flex;
                flex-direction: row;
                justify-content: space-around;

                button {
                    padding: 10px 20px;
                }

                .btn_blue {
                    button {
                        background-color: $site_blue;
                        color: white;
                        border: 1px solid $site_blue;

                        &:hover {
                            background-color: white;
                            color: $site_blue;
                            border: 1px solid $site_blue;
                        }
                    }
                }

                .btn_white {
                    button {
                        background-color: white;
                        color: $site_blue;
                        border: 1px solid $site_blue;

                        &:hover {
                            background-color: $site_blue;
                            color: white;
                        }
                    }
                }
            }
        }
    }

    #dish_details {
        padding: 10px 30px 30px 30px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);

        li {
            list-style-type: none;
            margin-bottom: 10px;
        }

        span {
            color: $site_blue;
            font-weight: 700;
            font-size: 0.8rem;
        }

        h2 {
            color: $site_blue;
            font-size: 2rem;
            font-weight: 400;

            span {
                color: black;
                font-size: 1rem;
                font-weight: 700;
            }
        }

        table {
            border: 1px solid rgba(0, 0, 0, 0.15);
        }

        .MuiTableCell-head {
            font-weight: 700;
            font-size: 1.1rem;
        }

        .MuiTableCell-body {
            font-weight: 300;
            font-size: 1rem;
            border: none;
            border-right: 1px solid rgba(0, 0, 0, 0.15);
        }
    }
}