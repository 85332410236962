$site-blue: #05944f;
$site_red: #d30000;
$site_gray: #f6f6f6;
$roboto: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
  Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
$roboto_mono: "Roboto Mono", monospace;
$IBM_condensed: "IBM Plex Sans Condensed", sans-serif;
$Inconsolata: "Inconsolata", monospace;

@import "./globalAsset/form_global.scss";

@import "./globalAsset/table_global.scss";

#logout-btn {
  padding: 10px 20px;
  display: flex;
  flex-direction: row-reverse;
  background-color: #f6f6f6;

  button {
    text-transform: capitalize;
    color: $site_red;
    font-weight: bolder;
    border: 1px solid $site_red;

    &:hover {
      background-color: $site_red;
      color: white;
    }
  }
}

#notify {
  background-color: $site_red;

  p {
    color: white;
    font-size: 30px;
  }
}

#transitions-popper {
  box-shadow: 4px 2px 4px 2px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.vertical_rule {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  height: auto;
  margin: 5% 8%;
}

#progress_bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.selected_addons_placeholder_icon {
  font-size: 10rem;
}

#loading_feedback {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: auto;
}

details {
  summary {
    font-weight: bold;
    cursor: pointer;
  }
}

.danger-btn {
  border: 1px solid $site_red;
  color: $site_red;
}

.success-btn {
  border: 1px solid green;
  color: green;
}

.printout_header_with_img {
  display: flex;
  flex-direction: row;
  justify-content: center;

  img {
    margin-top: 18px;
  }

  h2 {
    margin-left: 10px;
  }
}

.pending_order_close {
  color: $site_red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid $site_red;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: $site_red;
    color: white;
  }
}

#pos_filter_drawer_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin-top: 0;
    margin-bottom: 0;

    &:last-child {
      padding-top: 10px;
    }
  }

  aside {
    color: $site_red;
    font-size: 20px;
    cursor: pointer;

    &:hover {
      background-color: $site_red;
      color: white;
    }
  }
}

#use_initial_img {
  margin-left: 10px;
  cursor: pointer;
  color: $site-red;
}

#customer_search {
  margin-left: 50px;
}

// change the input box outline to the site color
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $site-blue !important;
}

.Mui-focused {
  color: $site-blue;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: $site-blue;
}

.MuiTab-root {
  border-color: $site-blue !important;
}

#mp_retrieving_meals {
  #loader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  h3 {
    font-weight: 300;

    span {
      font-weight: 500;
    }
  }
}

#mp_update_buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  button {
    &:last-child {
      border: 1px solid $site-blue;
      color: $site-blue;
      background-color: white;

      &:hover {
        background-color: $site_gray;
      }
    }
  }
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: $site-blue;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: $site-blue;
}

#category_btn {
  button {
    color: white !important;

    &:hover {
      color: $site-blue !important;
    }
  }
}

.emoji_style {
  font-size: 20px;
}

.MuiIconButton-label {
  color: $site_blue;
}

.print_btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;

  button {
    border: 1px solid #11617b;
    border-radius: 4px;
    font-family: $roboto;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: -0.01em;
    color: $site-blue;
    text-transform: capitalize;
    margin-left: 20px;

    span {
      padding: 0 5px;
    }
  }
}

#status_btn {

  button {
    background-color: $site-blue;
    color: white;
    margin-right: 20px;

    &:hover {
      background-color: $site_gray;
      color: $site-blue;
    }
  }
}

.select_delivery_time_qty {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  h2 {
    margin: 0;
    margin-left: 10px;
  }

  span {
    font-weight: 700;
    color: $site-blue;
  }

  padding: 20px;

  #survey_delivery_time_day {
    text-align: left;

    p {
      margin-left: 15px;
    }
  }
}

.verify_btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  button{
    padding: 20px 50px;
    background-color: white;
    border: 1px solid $site-blue;
  }
}