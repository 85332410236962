#notification {
    margin: 0 25px;

    #search_bar{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 20px 0;
        margin-bottom: 20px;
        border-bottom: 1px solid #E5E5E5;
    }

    #select_all{
        margin: 10px 0;
        font-weight: bolder;
    }
    
    #search_customer{
        margin: 0 25px 0 0;
    }

    .form-btn{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h1{
            color: #C4C4C4;
            cursor: pointer;

            &:hover{
                color: $site_red;
            }
        }

        button{
            padding: 10px 30px;
        }
    }
}